import React from 'react'
import styled from 'styled-components';
import BookSourceSection from "./common/BookSourceSection";
import useCurrentLocation from "../../../library/useCurrentLocation";
import useBookLibraryOffline from "../hooks/useBookLibraryOffline";
import {SearchBookSourceLibraryOfflineResponse} from "../SearchBookSourceLibraryResponse";
import ContentContainer from "../../../../common/styles/ContentContainer";
import RowSpacer from "../../../../common/styles/RowSpacer";
import LoanPossibleBadge from "./common/LoanPossibleBadge";
import ColSpacer from "../../../../common/styles/ColSpacer";
import BookSourceNoContent from "../BookSourceNoContent";
import BookSourceSectionLoading from "./common/BookSourceSectionLoading";
import breakpoints from "../../../../config/Breakpoints";

interface Props {
    isbn: string
}

const BookSourceOfflineLibrarySection: React.FC<Props> = ({isbn}) => {
    const [lat, long, isLocationLoading, locationName] = useCurrentLocation()
    const [librariesOffline, isLibraryOfflineLoading] = useBookLibraryOffline(isbn, lat, long)

    return (
        <BookSourceSection
            title={`도서관`}
            // @ts-ignore
            subTitle={locationName}
            // @ts-ignore
            body={
                isLocationLoading ?
                    <BookSourceSectionLoading message={'위치 정보를 가져오고 있어요'}/>
                    :
                    isLibraryOfflineLoading ?
                        <BookSourceSectionLoading/>
                        :
                        <Body librariesOffline={librariesOffline}/>
            }
        />
    )
}

interface BodyProps {
    librariesOffline: SearchBookSourceLibraryOfflineResponse[]
}

const Body: React.FC<BodyProps> = ({librariesOffline}) => {
    if (librariesOffline.length == 0) {
        return <BookSourceNoContent message={'도서관에는 책이 없어요'}/>
    }

    return (
        <>
            {
                librariesOffline.map(library => {
                    return (
                        <div className={'hover-effect w-100'}>
                            <a href={library.link!!} target={'_blank'} className={'w-100'}>
                                <RowSpacer size={10}/>
                                <Container>
                                    <div>
                                        <span className={'d-flex'}>
                                            {
                                                library.library.logo &&
                                                <>
                                                    <Icon src={library.library.logo}/>
                                                    <ColSpacer size={5}/>
                                                </>
                                            }
                                            <h6 className={'p-0 m-0 pt-1'}>{library.library.name}</h6>
                                        </span>

                                        <RowSpacer size={5}/>

                                        <span className={'d-flex'}>
                                            <LoanPossibleBadge isLoanPossible={library.loanPossible}/>
                                            {
                                                !library.loanPossible &&
                                                <>
                                                    <ColSpacer size={10}/>
                                                    <LoanPossibleBadge isLoanPossible={library.reservationPossible}
                                                                       possibleMessage={'예약가능'} impossibleMessage={'예약불가'}/>
                                                </>
                                            }
                                        </span>
                                    </div>

                                    <BookLocationContainer>
                                        <BookLocationText>{library.bookLocation}</BookLocationText>
                                    </BookLocationContainer>
                                </Container>
                            </a>
                        </div>
                    )
                })
            }
        </>
    )
}

const Icon = styled.img`
    width: 30px;
    height: 30px;
`

const Container = styled(ContentContainer)`
    justify-content: space-between;
`

const BookLocationContainer = styled.div`
    height: 100%;
    width: 100px;

    display: grid;
    place-items: center;
`

const BookLocationText = styled.h6.attrs({
    className: 'text-secondary'
})`
    padding: 0;
    margin: 0;
    
    font-size: 0.9rem;
    @media (max-width: ${breakpoints.md}) {
        font-size: 0.8rem;
    }

`

export default BookSourceOfflineLibrarySection
